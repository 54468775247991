.cart-item-left-container{
  cursor: pointer;
}

.price{
  margin-right: 10px;
}

.thumb-image{
  margin-left: 15px;
  width: auto;  /* Adjust width automatically to maintain aspect ratio */
  height: 50px; /* Full height of the parent container */
  object-fit: scale-down; /* Scale the image down to fit the container without distortion */
  object-position: left;
}



.cart-item-left-container{
  gap: 0px;
}

@media (max-width: 550px) {
  .distance{
    margin-right: 5px;
  }

  .cart-item-bike-name{
    font-size: 14px;
  }

  .price{
    font-size: 14px;
    margin-right: 5px;
  }

  .thumb-image{
    margin-left: 5px;
    height: 40px; /* Full height of the parent container */
  }


}

@media (max-width: 405px) {
  .distance{
    margin-right: 3px;
  }

  .cart-item-bike-name{
    font-size: 12px;
  }

  .price{
    font-size: 12px;
    margin-left: 5px;
  }

  .thumb-image{
    margin-left: 5px;
    height: 30px; /* Full height of the parent container */
  }
}