.footer{
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
}
.footer-content{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
    color: white;
    font-size: 11px;
    padding: 6px;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: 5%
}