.text-big{
  font-size: 34px;
}

.text-field-email{
  margin-bottom: 20px;
  width: 300px;
}

.above-input-text{
  margin-bottom: 5px;
}

.route-text-container{
  line-height: 1.4; 
}

.read-more-button {
  color: var(--link-blue);
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-size: inherit;
  margin-left: 5px;
}

.hidden{
  opacity: 0;
}

.inline-div {
  display: inline;
}

/* .left-selected{
  transform: translateX(50%);
}

.right-selected{
  transform: translateX(25%);
} */

.route-selected{
  transform: translateX(150%);
  transition: all 0.3s ease;
}

.hotel-selected{
  transform: translateX(50%);
  transition: all 0.3s ease;
}

.indicator{
  opacity: 1;
  height: 4px;
  width: 26px;
  background-color: var(--link-blue);
  
  //border-radius: 100px;
}

.indicator-container{
  width: 100%;
  height: 4px;
}

.menu-switcher {
  color: var(--link-blue);
  font-weight: bold;
}

.menu-container{
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .route-text-container{
    font-size: 20px;
  }

  .above-input-text{
    font-size: 20px;
  }
}