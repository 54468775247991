.places-textfield {
  width: 100%;
  margin-bottom: 5px;
}


/* 
/Floating cross button
.text-field-container {
  position: relative;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 50%;
} */