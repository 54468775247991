.submit-button {
  padding: 8px 24px;
  height: 50px;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  background: var(--color-primary80);
  border-radius: 8px;
  border: none;
  transition: 0.3s;
  color: #fff;
}

.secondary-button {
  padding: 8px 24px;
  margin-top:10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  border-radius: 8px; 
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  transition: 0.3s;
  color: var(--color-primary);
}

.submit-button:hover {
  transform: scale(1.05);
}

.secondary-button:hover {
  transform: scale(1.05);
}

.text-field{
  padding: 8px 6px;
  font-size: 16px;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
}

.text-field-number{
  width: 50px;
  text-align: right;
}