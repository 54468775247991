.cross-button{
  margin: 0px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.3s;
  float: right;
}

.booking-info-button{
  cursor: pointer;
  color: var(--color-primary);
}

.modal{
  padding: 0px;
}

.cart-item-container{
  flex-direction: row;
  padding: 30px;
}

.cart-content-container{
  display: flex;
  justify-content: space-between; /* This will push children to either end */
  align-items: left; /* Align items vertically */
}

.info-container{
  flex-grow: 1;
}

.cross-button:hover{
  transform: scale(1.05);
}

.info-img{
  width: 200px;
  height: 100%;
  border-radius: 20px;
  margin-top: 10px;
}

.thumb-image{
  margin-left: 30px;
  width: auto;  /* Adjust width automatically to maintain aspect ratio */
  height: 60px; /* Full height of the parent container */
  object-fit: scale-down; /* Scale the image down to fit the container without distortion */
  object-position: left;
}

.is-loading{
  background-color: #f0f0f0;
}

.error-popup{
  margin: 30px;
}

.popup-root{
  position: fixed;
  width: 50px;
}

@media (max-width: 700px) {
  .info-img{
    width: 120px;
    height: 100%;
    margin-top: 30px;
  }
  .cart-item-container{
    padding: 20px;
  }
}

@media (max-width: 460px) {
  .cart-item-container{
    padding: 15px;
  }

.popup-content{
  margin: 10px;
  padding: 0px;
}

[role='tooltip'].popup-content {
  width: 200px;
  background: blue;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}



  .info-img{
    width: 80px;
    height: 100%;
    margin-top: 50px;
  }
}

@media (max-width: 550px) {

  .info-img{
    width: 50px;
    height: 100%;
    margin-top: 50px;
  }
  .popup-content{
    margin: 10px;
    padding: 0px;
  }

  .cart-item-container{
    padding: 5px;
  }
}

@media (max-width: 380px) {

  .info-img{
    width: 50px;
    height: 100%;
    margin-top: 50px;
  }
  .popup-content{
    margin: 10px;
    padding: 0px;
  }

  .cart-item-container{
    padding: 5px;
  }
}