:root {
  --color-primary: #286B67;
  --color-primary80: rgba(40, 107, 103, 0.80);
  --color-secondary: #B31212;
  --transparent-white: rgba(255, 255, 255, 1);
  --link-blue: #007bff;
  --left-margin: 40px;
}

html, body {
  width: 100%;
  overflow-x: hidden;
  max-width: 100%;
}

body {
  margin: 0;
  /* League Spartan */
  font-family: 'Open sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
