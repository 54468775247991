.flex-right {
  display: flex;
  justify-content: space-between;
  margin-right: -5px;
}


.distant-text{
  line-height: 3.2; 
}

.flag-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 22px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
}

.flag-button-selected {
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  font-size: 28px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
}

.top-text {
  padding-top: 20px;
}

.top-container {
  margin-top: 0px;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.from-to-container {
  display: flex;
  flex-direction: row;
  justify-content: normal;
  gap: 20px;
}

.distanceButton {
  margin: 6px;
}

.drop-down {
  margin-top: 4px;
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 8px 12px 8px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
}

.drop-down:hover {
  background-color: #e9e9e9;
}

.drop-down:focus {
  border-color: #0056b3;
  outline: none;
}

.daily-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
}

.from-to-container > div {
  flex: 1 1 calc(33.33% - 20px); /* 3 columns with 20px gap */
  box-sizing: border-box;
}

.react-date-picker__wrapper{
  background-color: #ffffff;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px;
  border-radius: 8px;

}
.react-date-picker__button {
  color: red; /* Change color for font icons */
}

div.react-date-picker__wrapper {
  border: 1px solid var(--color-primary);
}

@media (max-width: 450px) {
.from-to-container {
  gap: 2px;
}
}