.text-field-mail{
  margin-bottom: 8px;
}

.main-container {
  margin: 3%;
  display: flex;
  gap: 40px;
  flex-direction: row;
}

.base-container{
  background-color: var(--transparent-white);
  border-radius: 20px;
  padding: 30px;
  transition: 0.3s;
}

.form-container {
  height: auto;
  width: 80%;
  max-width: 420px;
  min-width: 360px;
}

.response-container{
  width: 80%;
  max-width: 600px;
}

/* @media (min-width: 1500px) {
  .main-container {
    flex-direction: column;
    margin: 40px;
  }
} */

@media (max-width: 1100px) {
  .main-container {
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .main-container {
    width: 95%;
  }
  .form-container {
    width: 95%;
  }

  .response-container {
    width: 95%;
  }
}

@media (max-width: 500px) {
  .main-container {
    width: 96%;
  }
  .form-container {
    width: 96%;
    min-width: 320px;
  }
  
  .response-container{
    width: 96%;
  }
  .base-container{
    padding: 16px;
  }
}

@media (max-width: 360px) {
  .main-container {
    width: 99%;
  }
  .form-container {
    width: 99%;
    min-width: 320px;
  }
  
  .response-container{
    width: 99%;
    min-width: 320px;
  }
  .base-container{
    padding: 6px;
  }
}